@font-face {
    font-family: "Lato";
    src: url('../assets/fonts/lato/Lato-Regular.eot');
    src: url("../assets/fonts/lato/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/lato/Lato-Regular.woff2") format("woff2"),
    url("../assets/fonts/lato/Lato-Regular.woff") format("woff"),
    url("../assets/fonts/lato/Lato-Regular.ttf") format("truetype"),
    url("../assets/fonts/lato/Lato-Regular.svg#Lato-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/lato/Lato-Light.eot");
    src: url("../assets/fonts/lato/Lato-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/lato/Lato-Light.woff2") format("woff2"),
    url("../assets/fonts/lato/Lato-Light.woff") format("woff"),
    url("../assets/fonts/lato/Lato-Light.ttf") format("truetype"),
    url("../assets/fonts/lato/Lato-Light.svg#Lato-Light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/lato/Lato-Bold.eot");
    src: url("../assets/fonts/lato/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/lato/Lato-Bold.woff2") format("woff2"),
    url("../assets/fonts/lato/Lato-Bold.woff") format('woff'),
    url("../assets/fonts/lato/Lato-Bold.ttf") format("truetype"),
    url("../assets/fonts/lato/Lato-Bold.svg#Lato-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Bebas Neue";
    src: url("../assets/fonts/bebas-neue/bebasneueregular.woff2") format('woff2'),
    url("../assets/fonts/bebas-neue/bebasneueregular.woff") format('woff'),
    url("../assets/fonts/bebas-neue/bebasneueregular.ttf") format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Futura PT";
    src: url("../assets/fonts/futura-pt/FuturaPT-Bold.eot");
    src: url("../assets/fonts/futura-pt/FuturaPT-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/futura-pt/FuturaPT-Bold.woff") format("woff"),
    url("../assets/fonts/futura-pt/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}
